import { Options, Vue } from "vue-class-component";
import DropdownButtonMenu from "./dropdown-button-menu/dropdown-button-menu.vue";

@Options<DropdownButton>({
    name: "app-dropdown-button",
    components: {
        "app-dropdown-button-menu": DropdownButtonMenu,
    },
    props: {
        searchFunction: Function,
        disabledTableRows: {
            type: Boolean,
            default: false,
        },
        disabledTableExport: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: () => "md",
        },
        side: {
            type: String,
            default: () => "right",
        },
    },
    emits: ["update:modelValue"],
})
export default class DropdownButton extends Vue {
    private searchFunction: any;
    private dropdownButtonElement: HTMLElement;
    public isOpen = false;

    public mounted(): void {
        this.dropdownButtonElement = this.$refs.dropdownButton as HTMLElement;
        document.addEventListener("click", this.documentClick);
    }

    public unmounted(): void {
        document.removeEventListener("click", this.documentClick);
    }

    private documentClick(event: Event) {
        const target: HTMLElement = event.target as HTMLElement;
        if (this.dropdownButtonElement !== target && !this.dropdownButtonElement.contains(target)) {
            this.isOpen = false;
        }
    }

    private toggleDropdown() {
        this.isOpen = !this.isOpen;
    }
}
